<template>
  <v-app>
    <v-navigation-drawer
      app
      ref="nav"

      class="pt-2"
      v-model="drawer"

      :mini-variant="drawerBreakPoint"
      :expand-on-hover="drawerBreakPoint"
      :permanent="drawerBreakPoint"
      hide-overlay

    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="require('@/assets/railspire-icon.png')" />
          <!-- <v-img src="@/assets/railspire-icon.png" /> -->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text-h6">Railspire</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>

        <template
          v-for="(item, i) in items"
        >
        <v-divider v-if="item.divider" :key="i"></v-divider>
        <v-list-item
          v-else
          :key="i"
          :to="{name: item.to}"
          :disabled="item.disabled"
          router
          exact
        >
          <v-list-item-action>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>

        </template>


      </v-list>

    </v-navigation-drawer>

    <!-- clipped-left is needed when nav drawer is collapsible -->
    <v-app-bar app color="appbar" elevation="1" clipped-right>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!drawerBreakPoint"></v-app-bar-nav-icon>

      <v-toolbar-title v-text="$root.title"></v-toolbar-title>

      <!--
      <v-breadcrumbs
        :items="$root.crumbs"
        large
      ></v-breadcrumbs>
      -->

      <v-spacer></v-spacer>

      <slot name="appbar-header"></slot>

      <about-dialog v-model="about_dialog" />

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <slot name="appbar-menu"></slot>
          <v-list-item
            v-for="(item, i) in appbar_items"
            :key="i"
            :to="{name: item.to}"
            :disabled="item.disabled"
            router
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="about_dialog = true">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="'About'" />
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :to="{name: 'logout'}">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="'Logout'" />
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>


    <!--
      This footer may be useful to add global indicators. For now, I moved
      the version tags here.
    -->

    <v-footer app>
      <v-row>
        <v-col cols="3" sm="6" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <span class="caption font-weight-light">{{ $root.DEFAULT_TITLE }}</span>
          <span class="d-none d-sm-inline-block ml-1">
          &middot; <span class="caption font-weight-light">{{ $root.APP_NAME }} {{ $root.APP_VERSION }}</span>
          &middot; <span class="caption font-weight-light">{{ $root.API_NAME }} {{ $root.API_VERSION }}</span>
          </span>
        </v-col>
        <v-col cols="9" sm="6" class="text-right">
          <slot name="appbar-footer"></slot>
          <v-chip v-if="lniStore.conn && lniStore.is_online" color="success" label small class="ml-2">
            <v-icon left small>mdi-router-wireless</v-icon>{{ lniStore.name }} Online
          </v-chip>
          <v-chip v-else-if="lniStore.conn" color="" label small class="ml-2">
            <v-icon left small>mdi-router-wireless-off</v-icon>{{ lniStore.name }} Offline
          </v-chip>

          <v-icon v-if="lniListStore.conn" small color="success" class="ml-2"
            title="Websocket connected">mdi-server-network</v-icon>
          <v-icon v-else small color="grey" class="ml-2"
            title="Websocket disconnected">mdi-server-network-off</v-icon>





        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
import AboutDialog from "../components/AboutDialog"

import { useLNIStore } from "@/store/lni"
import { useLNIListStore } from "@/store/lnilist"

export default {
  setup () {
    const lniStore = useLNIStore()
    const lniListStore = useLNIListStore()
    return { lniStore, lniListStore }
  },
  components: {
    AboutDialog
  },
  data () {
    return {
      drawer: false,
      about_dialog: false,

      appbar_items: [
        { title: 'Profile', icon: 'mdi-account-circle', to: 'profile' },
        { title: 'Settings', icon: 'mdi-cog', to: 'settings' },
      ],
      items: [
        {
          icon: 'mdi-apps',
          title: 'Dashboard',
          to: 'dashboard'
        },
        { divider: true },
        {
          icon: 'mdi-map',
          title: 'Locations',
          to: 'location-list'
        },
        {
          icon: 'mdi-map',
          title: 'Switches',
          to: 'railswitch-list'
        },
        {
          icon: 'mdi-train-variant',
          title: 'Locomotives',
          to: 'locomotive-list'
        },
        {
          icon: 'mdi-router-wireless',
          title: 'LNIs',
          to: 'lni-list'
        },        
        {
          icon: 'mdi-car-door',
          title: 'Vehicles',
          to: 'vehicle-list'
        },        
        {
          icon: 'mdi-account-clock',
          title: 'Workforce',
          to: 'workforce-list'
        },                        
        {
          icon: 'mdi-clipboard-text',
          title: 'Missions',
          to: 'mission-list'
        },
        { divider: true },

        {
          icon: 'mdi-file-upload',
          title: 'LocoLog Upload',
          to: 'loco-log-uploader'
        },
        {
          icon: 'mdi-web',
          title: 'ATC Viewer',
          to: 'atc-viewer'
        },
        {
          icon: 'mdi-web',
          title: 'GIS Explorer',
          to: 'gis-explorer'
        },
        {
          icon: 'mdi-account',
          title: 'Users',
          to: 'user-list'
        },
        {
          icon: 'mdi-steering',
          title: 'GBK',
          to: 'GBK'
        },
        {
          icon: 'mdi-trash-can-outline',
          title: 'Obsolete',
          to: 'obsolete'
        },
        {
          icon: 'mdi-clipboard-text-clock',
          title: 'Connections',
          to: 'connection-list'
        },
        {
          icon: 'mdi-history',
          title: 'Traces',
          to: 'trace-list'
        },

      ]
    }
  },
  computed: {
    drawerBreakPoint () {
     return this.$vuetify.breakpoint.lgAndUp
    }
  }
}
</script>
