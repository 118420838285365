import config from './config'
import http from './http-common'

let state = {
  authenticated: false,
  error: false,
  token: null,
  user: null
};

// init
let token = localStorage.getItem(config.TOKEN_STORAGE_KEY)
if (token !== null){
  http.defaults.headers.Authorization = `Token ${token}`
  state.token = token
}

export default {
  login (email, password) {
    return new Promise((resolve, reject) => {
      http.post('auth/token/login/', {email, password})
        .then((res) => {
          let token = res.data.token
          localStorage.setItem(config.TOKEN_STORAGE_KEY, token);
          http.defaults.headers.Authorization = `Token ${token}`
          state.token = token
          resolve()
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      http.post('auth/token/logout/')
        .then((res) => {
          localStorage.removeItem(config.TOKEN_STORAGE_KEY)
          http.defaults.headers.Authorization = null
          state.token = null
          resolve()
        })
        .catch((res) => {
          reject(res)
        })
    })
  },
  isAuth () {
    return state.token !== null
  },
  getState () {
    return state
  }
}
