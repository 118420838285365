import config from "../config"

export default {
  methods: {
    parseMapQuery(query) {
      if (typeof query !== 'string'){
        console.log('not string. return')
        return {}
      }

      let latlon = config.MAP_LATLON_RE.exec(query)

      let data = {}
      if (latlon){
        data['latlon'] = [parseFloat(latlon[1]), parseFloat(latlon[2])]
      }
      else if (query.match(/^[a-zA-Z0-9]{32}$/)){
        data['id'] = query
      }
      return data
    }
  }
}

