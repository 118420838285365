'use strict'

const config = {
  API_URL: process.env.VUE_APP_API_URL,
  WS_URL: process.env.VUE_APP_WS_URL,
  // Doing this because the dev proxy server not working for these:
  TILE_URL: process.env.VUE_APP_TILE_URL,

  TOKEN_STORAGE_KEY: 'token',
  PROFILE_STORAGE_KEY: 'profile',

  // TODO: Move to .env.*.local (ignored by git) - Leaving it here for now to
  // eliminate an extra step.
  MAPBOX_ACCESS_TOKEN: "pk.eyJ1IjoiZXJpY3JzIiwiYSI6ImNrdjZwNzhuMTB3dHcyb253YjZqcHBpOHAifQ.v6PvL8-SDwOT6wFuGfYcJw",
  BING_MAPS_KEY: "AhA77MUZOepr_NOxwJ1sE__RZQGkCAQOUWoU0kz8qWkhrroIHaTFmpxtH-jBKrYf",
  THUNDERFOREST_MAPS_KEY: "552b589f87c545b1961242ca5bd3208a",


  MAP_LATLON_RE: /(^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/,
  MAP_DEFAULT_ZOOM: 11
}

// Use location.href if WS_URL is relative
function transformWSUrl(url) {
    if (url.match('^/')){
      let u = new URL(location.href)
      url = u.protocol.replace('http', 'ws') + '//' + u.host + url
    }
    return url
}

config.WS_URL = transformWSUrl(config.WS_URL)

export default config

