import config from '@/config'
import auth from "@/auth"

import http from "../http-common"

import { defineStore } from 'pinia'

// Global websocket handle
let conn = null

const useLNIStore = defineStore('lni', {
  state: () => {
    return {
      name: '',
      version: '',
      simulator: null,

      callback: [],
      //conn: null,

      is_online: null,
      locked: null,
      user_title: null,
      task_running: null,
      prev_task_running: null,
      control_mode: null,

      notch: 0,
      brake_mode: null,
      dynamic_brake: 0,
      independent_brake: 0,
      automatic_brake: 0,
      equalizing_reservoir: 0,
      reverser: null,

      timestamp: null,
      notch_target: null,
      accel: 0,
      acceleration: 0,
      speed: 0,
      heading: 0,
      elevation: 0,
      distance_from_start: null,
      distance_to_target: null,
      position_max: null,

      route: [],
      position: []

    }
  },
  actions: {
    // This must be called in created hook
    init(lni_id) {

      // Open lni specific websocket. Only one lives at a time, unless
      // multiple state instances can be created.

      const url = config.WS_URL + `/ocu/${lni_id}/`
      let authstate = auth.getState()
      document.cookie = 'Token=' + authstate.token + '; path=/';


      // Close previous connection
      console.log('conn:',conn)
      if (conn){
        console.log('[WS] Close previous websocket',conn)
        conn.close()
      }

      this.$reset()

      //if (!conn){
        console.log('[WS] Opening new websocket.. url:',url)
        conn = new WebSocket(url)
        console.log('[WS] opened',conn)
      //}

      conn.onmessage = (event) => {
        var container = JSON.parse(event.data)
        var msg = container['payload']

        this.$patch(msg)

        if (this.callback.length){
          for (let i=0; i<this.callback.length; i++){
            this.callback[i](msg)
          }
        }

      }

      conn.onopen = (event) => {
        this.sendCommand('status')
      }
      conn.onclose = (event) => {
        //this.open = false
      }

      http.get(`lni/${lni_id}`)
        .then((res) => {
          this.name = res.data.name
          console.log('name:'+this.name)
        })
    },

    setCallback(callback) {
      console.log('setCallback:',callback)
      this.callback.push(callback)
    },

    // Send command (request)
    async sendCommand (name, data) {
      let msg = new Object()
      msg["request"] = name
      msg["owner"] = auth.getState().token
      if (data){
        msg["data"] = data
      }
      let payload = JSON.stringify(msg)
      conn.send(payload)
    },

    async lock(data) { this.sendCommand('lock', data) },
    async unlock(data) { this.sendCommand('unlock', data) },
    async start () { this.sendCommand('start') },
    async stop () { this.sendCommand('stop') },
    async emStop () { this.sendCommand('emstop') },
    async setStatus(data) { this.sendCommand('set', data) },
    async setValue(k, v) {
      let data = new Object()
      data[k] = v
      this.sendCommand('set', data)
    },

    async setPosition (val) { this.setStatus({"gps_position": val }) },
    async setTarget (val) { this.setStatus({"gps_target": val}) },
    async setRoute () {
      this.setStatus({
        "route_start": this.route_start,
        "route_end": this.route_end
      })
    },
    async lockMode (val) { this.sendCommand('lockmode', {"control_mode": val}) },
    async changeControlMode (val) { this.setStatus({"control_mode": val}) },
    async changeBrakeMode (val) { this.setStatus({"brake_mode": val}) },
    async changeReverser (val) { this.setStatus({"reverser": val}) },
    async changeNotch (val) { this.setStatus({"notch": val}) },
    async changeIndBrake (val) { this.setStatus({"independent_brake": val}) },
    async changeAutoBrake (val) { this.setStatus({"automatic_brake": val}) },
    async changeDynBrake (val) { this.setStatus({"dynamic_brake": val}) },
    async changePosition (val) { this.setStatus({"position": val}) },
  },
  getters: {
    //isConnected: () => Boolean(conn),
    isOnline: (state) => Boolean(state.is_online),
    isLocked: (state) => Boolean(state.locked),
    isLockedByMe: (state) => state.locked === auth.getState().token,
    isLockedByOther: (state) => state.locked && state.locked !== auth.getState().token,

    isMoving: (state) => state.speed > 0,
    isTaskRunning: (state) => state.task_running,
    isReady: (state) => !state.isTaskRunning,

    isControlAllowed: (state) => state.isLockedByMe,
    isModeEditAllowed: (state) => state.isLockedByMe,

    isManual: (state) => state.control_mode === 'manual',
    isAuto: (state) => state.control_mode === 'auto',

    isMonitorAllowed: (state) => state.isReady && (!state.isLocked || state.isLockedByMe),
    isManualAllowed: (state) => state.isReady && (!state.isLocked || state.isLockedByMe),
    isAutoAllowed: (state) => state.isReady && (!state.isLocked || state.isLockedByMe)
  }
})

export { useLNIStore }

