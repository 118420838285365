import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './registerServiceWorker'

import 'typeface-roboto'
import '@mdi/font/css/materialdesignicons.css'

import formatMixin from './mixins/formatMixin'
import parseMixin from './mixins/parseMixin'
import AppLayout from './layouts/AppLayout'
import BlankLayout from './layouts/BlankLayout'
import {useSettingsStore} from "@/store/settings"


Vue.config.productionTip = false

const DEFAULT_TITLE = "Railspire"
const COMPANY_NAME = "Railspire LLC"
const APP_NAME = "Seabreeze"
const APP_VERSION = "v0.2.3"



// Pinia state
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = new Vue({
  data: {
    title: DEFAULT_TITLE,
    crumbs: [{text:'First'},{text:'Second'}],

    // todo: put these info config
    DEFAULT_TITLE: DEFAULT_TITLE,
    COMPANY_NAME: COMPANY_NAME,
    APP_NAME: APP_NAME,
    APP_VERSION: APP_VERSION,
    API_NAME: null,
    API_VERSION: null
  },
  computed: {
    titleFromCrumbs () {
      return this.crumbs.map(x => x.text).join(" / ")
    }
  },
  methods: {
    setTheme (theme) {
      if (theme == 'browser') {
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches){
          this.$vuetify.theme.dark = true
        }
        else {
          this.$vuetify.theme.dark = false
        }
      }
      else {
        this.$vuetify.theme.dark = theme == 'dark' ? true : false
      }
    },
    getThemeDarkLight () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },
  watch: {
    title () {
      document.title = this.title + ' - ' + DEFAULT_TITLE
    }
    /*
    crumbs () {
      let title = this.crumbs.map(x => x.text).join(" / ")
      document.title = title
    }
    */
  },
  router,
  vuetify,
  pinia,
  render: h => h(App)
})

// Global layouts
Vue.component('AppLayout', AppLayout)
Vue.component('BlankLayout', BlankLayout)

// Global mixins
Vue.mixin(formatMixin) // this one depends on pinia settings
Vue.mixin(parseMixin)

// Initialize settings store and assign to app (this.$root)
const settings = useSettingsStore()
app.settings = settings

app.$mount('#app')

