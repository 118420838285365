<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html { overflow-y: auto !important }
</style>
<template>
  <router-view></router-view>
</template>
<script>
import http from './http-common'

import {useSettingsStore} from "@/store/settings"


const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

export default {
  setup () {
    const settings = useSettingsStore()
    return { settings }
  },
  mounted() {
    this.$root.setTheme(this.settings.theme)

    // Get api version
    http.get('info')
      .then((res) => {
        this.$root.API_NAME = capitalize(res.data.name)
        this.$root.API_VERSION = res.data.version
      })
  },
  data () {
    return {

    }
  }
}
</script>
