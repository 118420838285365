// Follow vue3 pattern even though we're on vue2 as the filter
// pattern has gone away in 3
// https://jerickson.net/how-to-format-dates-in-vue-3/

//import dayjs from 'dayjs'
//import { duration } from 'dayjs/plugin/duration'

import * as dayjs from 'dayjs'
import * as duration from 'dayjs/plugin/duration'
import {useSettingsStore} from "@/store/settings"

dayjs.extend(duration)


const DTFORMATS = {
  'long' : 'MMM D, YYYY h:mm a',
  'short': 'MMM D, YYYY'
}

const arr16wind = ["N","NNE","NE","ENE","E","ESE", "SE", "SSE","S","SSW","SW","WSW","W","WNW","NW","NNW"]


function metersToBest(meters, metric) {
  let val = null
  let unit = null

  if (metric){
    if (meters >= 1000){ val = (meters / 1000).toFixed(1); unit = 'km' }
    else { val = Math.round(meters); unit = 'm' }
  }
  else {
    let feet = meters * 3.28084
    if (feet >= 5280){ val = (feet / 5280).toFixed(1); unit = 'mi' }
    else { val = Math.round(feet); unit = 'ft' }
  }
  return {
    val: val,
    unit: unit
  }
}

export default {
  created () {
    // Settings is available here (after vue initialization)
    // If you need a settings flag(ex metric) outside of the vue context
    // you'll need to pass it in.
    this.settings = useSettingsStore()
  },
  methods: {
    convertSpeed(kph) {
      return this.settings.metric ? kph : kph * 0.6213712
    },

    formatSpeed(kph) {
      let val = this.convertSpeed(kph)
      let unit = this.settings.metric ? 'kph' : 'mph'
      if (val != 0){
        val = val.toFixed(1)
      }
      return `${val} ${unit}`
    },

    formatLength(meters) {
      let v = metersToBest(meters, this.settings.metric)
      return `${v.val} ${v.unit}`
    },

    // https://en.wikipedia.org/wiki/Cardinal_direction
    formatCompass(deg) {
      let val=parseInt((deg/22.5)+.5)
      return arr16wind[(val % 16)]
    },

    formatDate(dateString, format='MMM D, YYYY') {
      const date = dayjs(dateString);
      if (format in DTFORMATS){
        format = DTFORMATS[format]
      }
      return date.format(format)
    },
    formatDuration(start, end) {
       let d = dayjs.duration(dayjs(start).diff(dayjs(end)))
       return d.format('mm:ss')
    },
    formatUUID(uuid, format='short') {
      uuid = uuid.toString()
      if (format === 'short'){
        return uuid.slice(-7)
      }
      return uuid
    },
    formatDistance(meters, format='meters') {
      if (format === 'meters'){
        return parseInt(meters).toString() + 'm'
      }
    }
  }
}

