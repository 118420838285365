import Vue from "vue"
import Router from "vue-router"
import auth from "./auth"

Vue.use(Router)


const requireAuthenticated = (to, from, next) => {
  if (!auth.isAuth()) {
    console.log('!auth.isAuth')
    next({name:'login'})
  } else {
    next()
  }
}

const redirectLogout = (to, from, next) => {
  auth.logout()
    .then(() => next({name:'login'}))
}


let auth_routes = [
    {
      path: "login",
      name: "login",
      component: () => import("./views/AuthLogin"),
      meta: { title: 'Login', public: true }
    },
    {
      path: 'logout',
      name: 'logout',
      beforeEnter: redirectLogout
    },
    {
      path: "register",
      name: "register",
      component: () => import("./views/AuthRegister"),
      meta: { title: 'Register', public: true }
    },
    {
      path: "activate/:uid/:token",
      name: "activate",
      component: () => import("./views/AuthActivate"),
      meta: { title: 'Account Activation', public: true }
    },
    {
      path: "password/reset",
      name: "passwordreset",
      component: () => import("./views/AuthPasswordReset"),
      meta: { title: 'Password Reset', public: true }
    },
    {
      path: "password/reset/confirm/:uid/:token",
      name: "passwordresetconfirm",
      component: () => import("./views/AuthPasswordResetConfirm"),
      meta: { title: 'Password Reset Confirmation', public: true }
    },
]

let app_routes = [
  {
    path: "dashboard",
    name: "dashboard",
    component: () => import("./views/Dashboard"),
    meta: { title: 'Dashboard' }
  },
  {
    path: "railswitch",
    name: "railswitch-list",
    component: () => import("./views/RailSwitchList"),
    meta: { title: 'Switches' }
  },

  {
    path: "railswitch/:id",
    name: "railswitch-createupdate",
    component: () => import("./views/RailSwitchCreateUpdate"),
    meta: { }
  },

  {
    path: "locations/:id",
    name: "location-createupdate",
    component: () => import("./views/LocationCreateUpdate"),
    meta: { }
  },


  {
    path: "locations",
    name: "location-list",
    component: () => import("./views/LocationList"),
    meta: { title: 'Locations' }
  },
  {
    path: "locations/:id",
    name: "location-createupdate",
    component: () => import("./views/LocationCreateUpdate"),
    meta: { }
  },
  {
    path: "locations/:id/detail",
    name: "location-detail",
    component: () => import("./views/LocationDetail"),
    meta: { }
  },

  {
    path: "locomotives",
    name: "locomotive-list",
    component: () => import("./views/LocomotiveList"),
    meta: { title: 'Locomotives' },
  },
  {
    path: "locomotives/:id",
    name: "locomotive-createupdate",
    component: () => import("./views/LocomotiveCreateUpdate"),
    meta: { }
  },
  {
    path: "locomotives/:id/detail",
    name: "locomotive-detail",
    component: () => import("./views/LocomotiveDetail"),
    meta: { }
  },

  {
    path: "missionanalysis/:scac",
    name: "locomotive-missionanalysis",
    component: () => import("./views/LocomotiveMissionAnalysis"),
    meta: { }
  },
  {
    path: "missionanalysis/:uuid/detail",
    name: "locomotive-missionanalysis-detail",
    component: () => import("./views/LocomotiveMissionAnalysisDetail"),
    meta: { }
  },
  
  {
    path: "lnis",
    name: "lni-list",
    component: () => import("./views/LNIList"),
    meta: { title: 'LNIs' },
  },
  {
    path: "lnis/:id",
    name: "lni-createupdate",
    component: () => import("./views/LNICreateUpdate"),
    meta: { }
  },
  {
    path: "lnis/:id/detail",
    name: "lni-detail",
    component: () => import("./views/LNIDetail"),
    meta: { }
  },
  {
    path: "lnis/:id/simulate",
    name: "lni-simulate",
    component: () => import("./views/LNISim"),
    meta: { }
  },
  {
    path: "lnis/:id/control",
    name: "lni-control",
    component: () => import("./views/LNIControl"),
    meta: { }
  },

  {
    path: "workforce",
    name: "workforce-list",
    component: () => import("./views/WorkforceList"),
    meta: { title: 'Workforce' },
  },
  {
    path: "workforce/:id",
    name: "workforce-createupdate",
    component: () => import("./views/WorkforceCreateUpdate"),
    meta: { }
  },
  {
    path: "workforce/:id/detail",
    name: "workforce-detail",
    component: () => import("./views/WorkforceDetail"),
    meta: { }
  },

  {
    path: "vehicle",
    name: "vehicle-list",
    component: () => import("./views/VehicleList"),
    meta: { title: 'Vehicles' },
  },
  {
    path: "vehicle/:id",
    name: "vehicle-createupdate",
    component: () => import("./views/VehicleCreateUpdate"),
    meta: { }
  },
  {
    path: "vehicle/:id/detail",
    name: "vehicle-detail",
    component: () => import("./views/VehicleDetail"),
    meta: { }
  },  
  {
    path: "missions",
    name: "mission-list",
    component: () => import("./views/MissionList"),
    meta: { title: 'Missions' },
  },
  {
    path: "missions/:id",
    name: "mission-createupdate",
    component: () => import("./views/MissionCreateUpdate"),
    meta: { }
  },
  {
    path: "missions/:id/detail",
    name: "mission-detail",
    component: () => import("./views/MissionDetail"),
    meta: { }
  },

  {
    path: "mission_templates",
    name: "mission-template-list",
    component: () => import("./views/MissionTemplateList"),
    meta: { title: 'Mission Templates' },
  },

  {
    path: "connections",
    name: "connection-list",
    component: () => import("./views/ConnectionList"),
    meta: { title: 'Connection History' },
  },

  {
    path: "traces",
    name: "trace-list",
    component: () => import("./views/TraceList"),
    meta: { title: 'Traces' },
  },
  {
    path: "playback/:id",
    name: "playback",
    component: () => import("./views/Playback"),
    meta: { title: 'Playback' },
  },



  {
    path: "GBKOCU",
    name: "GBK",
    component: () => import("./views/GBK"),
    meta: { title: 'GBK-OCU' },
  },
  {
    path: "gis-explorer",
    name: "gis-explorer",
    component: () => import("./views/GISExplorer"),
    meta: { title: 'GIS Explorer' }
  },
  {
    path: "atc-viewer",
    name: "atc-viewer",
    component: () => import("./views/ATCViewer"),
    meta: { title: 'ATC Viewer' }
  },
  {
    path: "route-timeline",
    name: "route-timeline",
    component: () => import("./views/Timeline"),
    meta: { title: 'Route Timeline' }
  },
  {
    path: "track-viewer",
    name: "tracks",
    component: () => import("./views/TrackViewer"),
    meta: { title: 'Track Viewer' }
  },
  {
    path: "elv-viewer",
    name: "elevation",
    component: () => import("./views/ElevationViewer"),
    meta: { title: 'Elevation Viewer' }
  },
  {
  path: "elv-viewer/:segment",
  name: "elevation",
  component: () => import("./views/ElevationViewer"),
  meta: { title: 'Elevation Viewer' }
  },
  {
    path: "tracer",
    name: "tracer",
    component: () => import("./views/Tracer"),
    meta: { title: 'Web Tracer' }
  },
  {
    path: "monitor.bak/:id",
    name: "monitor-specific",
    component: () => import("./views/LNIMonitor"),
    meta: { title: 'Monitor' }
  },
  {
    path: "monitor/:id",
    name: "monitor2",
    component: () => import("./views/LNIMonitor2"),
    meta: { title: 'Monitor' }
  },
  {
    path: "loco-log-uploader",
    name: "loco-log-uploader",
    component: () => import("./views/LocoLogUploader"),
    meta: { title: 'LocoLogUploader'}
  },



  // profile stuff
  {
    path: "profile",
    name: "profile",
    component: () => import("./views/Profile"),
    meta: { title: 'Profile' }
  },
  {
    path: "settings",
    name: "settings",
    component: () => import("./views/Settings"),
    meta: { title: 'Settings' }
  },


  // admin stuff
  {
    path: "user",
    name: "user-list",
    component: () => import("./views/UserList"),
    meta: { title: 'Users' }
  },

  {
    path: "obsolete",
    name: "obsolete",
    component: () => import("./views/Obsolete"),
    meta: { title: 'Obsolete' }
  },

]

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/auth",
      meta: { },
      component: () => import("./layouts/EmptyRouterLayout"),
      children: auth_routes
    },
    {
      path: '/',
      name: 'home',
      redirect: { name: 'dashboard' },
      meta: {
        // Everything under /app requires authentication
        requiresAuth: true,
        title: 'App'
      },
      component: () => import("./layouts/EmptyRouterLayout"),
      children: app_routes
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import("./views/NotFound")
    }
  ]
})



router.beforeEach((to, from, next) => {
  console.log("beforeEach to:",to)
  if (!to.meta.public){
    console.log('requireAuthenticated')
    requireAuthenticated(to, from, next)
  }

  next()
})

router.beforeResolve((to, from, next) => {
  //router.app.$root.title = to.meta.title
  next()
})

router.afterEach((to, from) => {
  if ('title' in to.meta){
    router.app.$root.title = to.meta.title
  }
})


export default router
