import axios from "axios"
import config from "./config"

export function getCookie(name) {
    var cookieArr = document.cookie.split(";")
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=")
        if(name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1])
        }
    }
    return null
}

export const http = axios.create({
    baseURL: config.API_URL,
    headers: {
      "Content-type": "application/json",
      //"X-CSRFToken": getCookie('csrftoken')
    }
})

export const multiparthttp = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-type": "multipart/form-data"
    },
    maxContentLength: 10000000,
    maxBodyLength: 10000000,
    emulateJSON: true,
})

import qs from 'qs'
multiparthttp.interceptors.request.use((request) => {
  if (request.data && request.headers['Content-Type'] === 'multipart/form-data') {
    request.data = qs.stringify(request.data);
}
return request;
});

// Add a response interceptor
http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
      localStorage.removeItem(config.TOKEN_STORAGE_KEY)
      // would be better to catch this with a ui message/dialog
      console.log('401 redirect: window.location:'+window.location)
      let url = new URL(window.location)
      if (!url.pathname.match('^/auth/')){
        window.location = '/auth/login'
      }
  } else {
      return Promise.reject(error)
  }
})


export default http

