import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      metric: true,
      theme: 'browser'
    }
  },
  persist: true,
  actions: {

  },
  getters: {

  }
})
