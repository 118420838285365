<template>
  <v-app>
    <v-main>
      <v-container class="col-sm-8 col-md-6 col-lg-4 col-xl-3 mx-auto">
        <router-link :to="{name:'home'}">
          <v-img :src="require('@/assets/railspire-name.png')" class="mx-auto my-5" style="width:238px;"/>
        </router-link>
        <slot />

        <div class="mx-auto text-center my-5">
          <span class="d-block font-weight-light">&copy; {{ new Date().getFullYear() }} {{ $root.COMPANY_NAME }}</span>
          <span class="caption font-weight-light">{{ $root.APP_NAME }} {{ $root.APP_VERSION }}</span> &middot;
          <span class="caption font-weight-light">Server API {{ $root.API_VERSION }}</span>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>
