import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                //appbar: colors.grey.lighten4,
                primary: '#86bb25',//colors.lightGreen.darken3,
                secondary: '#999999',//colors.grey.darken3,
                accent: '#0090b1'
            },
            dark: {
                //appbar: colors.grey.darken4,
                primary: '#86bb25',//colors.lightGreen.darken3,
                secondary: '#999999',// colors.grey.darken3,
                accent: '#0090b1'
            }
        }
    }
}

export default new Vuetify(opts)
