import config from '@/config'
import auth from "@/auth"
import Vue from 'vue'


import { defineStore } from 'pinia'

const useLNIListStore = defineStore('lnilist', {
  state: () => {
    return {
      lnis: [],
      obj: {}
    }
  },
  actions: {
    init() {
      const url = config.WS_URL + `/ocu/`
      console.log('lniListStore.init()')
      let authstate = auth.getState()
      document.cookie = 'Token=' + authstate.token + '; path=/';

      this.$reset()

      //if (this.conn){
      //  this.conn.close()
      //}
      console.log('WebSocket url:',url)
      this.conn = new WebSocket(url)

      this.conn.onmessage = (event) => {
        var container = JSON.parse(event.data)
        var msg = container['payload']
        //console.log('onmessage:',msg)

        //this.$state = msg
        let lni = 'noname'
        if ('source' in container){
          lni = container['source']['lni']
        }
        //this.lnis[lni] = msg
        //this.lnis.push(msg)

        if ('Payload' in msg) {
          msg = msg['Payload']
          console.log("Adapting to ", msg)
          console.log(msg['Latitude Deg'])
          msg['latitude'] = msg['Latitude Deg']
          msg['longitude'] = msg['Longitude Deg']
        }

        this.lnis.forEach((r) => {
          if (r.id == lni){
            Object.keys(msg).forEach((k) => {
              Vue.set(r, k, msg[k])
            })

            //r.online = msg.online
          }
          //Vue.set(this.obj[lni], 'time', msg.time)
          //Vue.set(this.obj[lni], 'online', msg.online)
        })

        //this.obj[lni].time = msg.time
        //this.obj[lni].online = msg.online
        //this.obj[lni].speed = msg.speed

        //console.log(`onmessage: ${lni} obj:`, msg)
      }

      this.conn.onopen = (event) => {
        // subscribe
        //this.conn.send(JSON.stringify({"type":"REQ_STATUS"}))
        this.conn.send(JSON.stringify({"sub":["all"]}))

      }
      this.conn.onclose = (event) => {
        //this.open = false
      }


    },
    setItem (obj) {

    },
    async req_start () {
      var msg = {"type": "REQ_START"}
      this.conn.send(JSON.stringify(msg))
    }

  },
  getters: {
    //doubleCount: (state) => state.count * 2,
    //wsConn: (state) => state.conn
  }
})

export { useLNIListStore }

