<template>
  <div class="text-center">
    <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          About
        </v-card-title>

        <v-card-text>
          <h3>
            <v-avatar>
              <v-img :src="require('@/assets/railspire-icon.png')" />
            </v-avatar>
            {{ $root.DEFAULT_TITLE }}
          </h3>

          <div class="font-weight-light">
            {{ $root.APP_NAME }} {{ $root.APP_VERSION }}
          </div>
          <div class="font-weight-light">
            {{ $root.API_NAME }} {{ $root.API_VERSION }}
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['value'],
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
